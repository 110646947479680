<template>
  <div class="body_">
    <my-fram>
      <div slot="default" class="content">
       <div class="top">
         <div class="top_left">当前位置：综合查询</div>
         <div class="top_right">
           <div class="button" @click="goHome">返回</div>
         </div>
       </div>
       <div class="foot">
         <div class="one">
           <div class="title"><span>个人信息</span> <button @click="updateuserinfo">修改</button></div>
           <div class="detail">
             <div class="avatar">
              <el-image
                class="avatar_img"
                :src="avatar_img"
                :fit="fit">
              </el-image>
             </div>
             <el-row class="inf">
               <el-col :span="9"  class="inf_l">姓名</el-col>
               <el-col :span="15"  class="inf_r">{{owner.ownerName}}</el-col>
               <el-col :span="9" class="inf_l1">电话</el-col>
               <el-col :span="15"  class="inf_r1">{{owner.phone}}</el-col>
               <el-col :span="9" class="inf_l1">身份证号</el-col>
               <el-col :span="15"  class="inf_r1">{{owner.idcard}}</el-col>
               <el-col :span="9" class="inf_l1">录入时间</el-col>
               <el-col :span="15"  class="inf_r1">{{owner.createTime}}</el-col>
             </el-row>
           </div>
         </div>
         <div class="two">
           <div class="title">
             <div class="l">您的房屋信息</div>
             <div class="r" @click="dialogVisible = true">切换</div>
             <el-dialog
               class="fakeDialog"
               title="房屋切换"
               :visible.sync="dialogVisible"
               width="50%"
               :before-close="close"
               :show-close="false">
               <span>
                   <el-table :data="tableDataAll"  style="width: 100%"
                             :header-cell-style="{background:'#019B84',color:'#fff',fontSize:'1.2rem'}"
                             :row-style="{background:'#F1F4FF'}">
                    <el-table-column prop="villageName" label="小区名称" width="117" align="center" ></el-table-column>
                    <el-table-column prop="towerNum" label="楼栋号" width="117" align="center"></el-table-column>
                    <el-table-column prop="unitName" label="单元" width="117" align="center"></el-table-column>
                    <el-table-column prop="houseNum" label="户室" width="117" align="center"></el-table-column>
                    <el-table-column prop="coveredarea" label="面积" width="117" align="center"></el-table-column>
                    <el-table-column prop="operate" label="操作" align="center">
                      <el-row slot-scope="scope">
                        <el-button @click="checkHouse(scope.$index)">当前选择</el-button>
                      </el-row>
                    </el-table-column>
                  </el-table>
                </span>
               <span slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false" class="dialog_button">返回</el-button>
                </span>
             </el-dialog>
           </div>
           <div class="detail">
             <el-row class="inf">
               <el-col :span="9" class="inf_l">小区名称</el-col>
               <el-col :span="15"  class="inf_r">{{tableData.villageName}}</el-col>
               <el-col :span="9" class="inf_l1">楼幢</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.towerNum}}</el-col>
               <el-col :span="9" class="inf_l1">单元</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.unitName}}</el-col>
               <el-col :span="9" class="inf_l1">户室</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.houseNum}}</el-col>
               <el-col :span="9" class="inf_l1">房屋面积</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.coveredarea}}</el-col>
               <el-col :span="9" class="inf_l1">所属物业</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.wyName}}</el-col>
               <el-col :span="9" class="inf_l1">所属开发商</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.kfsName}}</el-col>
               <el-col :span="9" class="inf_l1">建设时间</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.setttingTime}}</el-col>
               <el-col :span="9" class="inf_l1">录入时间</el-col>
               <el-col :span="15"  class="inf_r1">{{tableData.createTime}}</el-col>

             </el-row>
           </div>
         </div>
         <div class="three">
           <div class="title">
             缴费信息
           </div>
           <div class="detail">
             <div class="jfxx">
               <div>最后一次缴费时间：<br> {{lastPayTime==null ? '暂无缴费':lastPayTime}}</div>
             </div>
             <div class="kyye">
               <div class="amt">{{tableData.houseacctBal}}</div>
               <div class="amt_dec">房屋可用余额</div>
             </div>
             <div class="anniu">
               <div class="jfjl" @click="goPayRecord()">缴费记录</div>
               <div class="lzjl" @click="goExpendRecord()">列支记录</div>
             </div>
           </div>
         </div>
       </div>
      </div>
    </my-fram>


    <!--修改手机号码弹出层-->
    <el-dialog
      class="phoneDialog"
      title="修改手机号码"
      :visible.sync="dialogPhone"
      width="40%"
      :show-close="true"
      :close-on-click-modal="false">
      <div class="dialogPhoneBox">
          <p>当前手机号：<span>136****8769</span></p>
          <p>新手机号码：<input type="text" v-model="newphone"></p>
      </div>
      <div slot="footer" class="dialog-footer phoneFooter">
          <el-button @click="updatephone" class="dialog_button">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MyFram from '@/components/myFram'
export default {
  name: 'Query',
  components: { MyFram },
  data () {
    return {
      dialogPhone:false,
      dialogVisible: false,
     /* avatar_img: require('../assets/image/avatar.png'), */
	  avatar_img:'',
      fit: 'contain',
      url: this.$Config.base_server,
      lastPayTime:'',
      tableData: [],
	  newphone:"",
      tableDataAll:[],
      owner:[]
    }
  },
  mounted() {
      var idCard=this.$utils.getIdcard();
	  var photo=this.$utils.getphoto();
     if (idCard==""){
      this.$alert("请将身份证放识别区","消息提示");
      return false;
      }
	 this.avatar_img=photo;
	 this.getowner(idCard);
     this.getMyHouse();
  },
  methods: {
	  
	getowner(idCard){
		
		 this.$axios.post(this.url+'/getOwner?idCard='+idCard).then((res)=>{
		  if (res.data.resp_code=='0'){
		    this.owner=res.data.datas;
		  }else{
		    this.$message.error('对不起,查询出错了');
		  }
		 })
	} , 
	  
    getMyHouse(){
      var idCard=this.$utils.getIdcard();
      if (idCard==""){
        this.$alert("请将身份证放识别区","消息提示");
        return false;
      }
      this.$axios.post(this.url+'/getHouse?idCard='+idCard).then((res)=>{
        if (res.data.resp_code=='0'){
          if (res.data.datas==null){
             this.$message.error('对不起,没查询到房屋信息');
             return;
          }
          this.tableDataAll=res.data.datas;
          this.tableData=res.data.datas[0];
          this.$axios.post(this.url+'/QuerylastPayTime?houseId='+this.tableData.houseId).then((res2)=>{
            if (res2.data.resp_code=='0'){
              this.lastPayTime=res2.data.datas;
            }else{
              this.$message.error('对不起,查询出错了');
            }
          })
        }else{
          this.$message.error('对不起,查询出错了');
        }
      })
    },
	
	updateuserinfo(){
		this.newphone="";
		this.dialogPhone=true;
	},
	updatephone(){
		const phone=this.newphone;
		const idCard=this.$utils.getIdcard();
		if (idCard==""){
		  this.$alert("请将身份证放识别区","消息提示");
		  return false;
		}
		if(phone==""){
			this.$alert("请输入手机号","消息提示");
			return false;
		}
		this.$axios.get(this.url+'/updatephone?idCard='+idCard+"&phone="+phone).then((res)=>{
		  	if(res.data.resp_code==0){
				this.$message.success("修改成功");
				this.dialogPhone=false;
			  this.getowner(idCard);
			}else{
				this.$message.error(res.data.resp_msg);
			}
			
		})
	},
    checkHouse(houseId){
      const  that=this;
      that.tableData=that.tableDataAll[houseId];
      that.dialogVisible=false;
    },
    goHome () {
      this.$router.push({ path: '/' })
    },
    goPayRecord () {
      this.$router.push({ path: '/payRecord',query: {houseId:this.tableData.houseId} })
    },
    goExpendRecord () {
      this.$router.push({ path: '/expendRecord',query: {houseId:this.tableData.houseId} })
    }
  }
}
</script>

<style scoped  lang="stylus">
.body_{
  height: 100%;
  width: 100%;
}

  .dialogPhoneBox{
    width 100%
    display flex
    align-items flex-start
    flex-direction column
    p{
      margin-left 15%
      font-size 18px
      input{
        width 250px
        height 35px
      }
    }
    p:nth-of-type(2){
      margin-top 15px
    }
  }
    .phoneFooter{
      width 100%
      display flex
      align-items center
      justify-content center
      button{
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border none
        color #fff
        font-size 1.2rem
      }
    }
.content{
  height 100%
  width 100%
  display: flex;
  flex-direction: column;
  .top{
    width 100%
    height 15%
    display flex
    flex-direction row
    .top_left{
      width 85%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      justify-items center
      color #FFFFFF
    }
    .top_right{
      width 15%
      line-height 100%
      font-size 1.725rem
      display flex
      flex-direction column
      justify-content center
      align-items flex-end
      .button{
        width 5rem
        height 2.5rem
        line-height 2.4rem
        text-align center
        background-image linear-gradient(to bottom, #BDE4DD,#06B198)
        border-radius: .3rem;
        font-size 1.2rem
        color #fff
        cursor pointer
      }
    }
  }
  .foot{
    width 100%
    height 85%
    display flex
    flex-direction row
    .one{
      width: 28%;
      height: 100%;
      background #DBEEEC
      display flex
      flex-direction column
      border-radius: 1rem;
      .title{
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border: 1px solid #82D5FF80 ;
        border-bottom none
        height 13%
        width 100%
        background #019B84
        color #FFFFFF
        display flex
        flex-direction row
        align-items center
        text-indent 2rem
        font-size 1.5rem
        justify-content space-between
        button{
          margin-right 2rem
          width 5rem
          height 2.5rem
          line-height 2.5rem
          text-indent 0
          display flex
          justify-content center
          background-image linear-gradient(to bottom, #BDE4DD,#06B198)
          border-radius: .3rem;
          font-size 1.2rem
          color #fff
          border none
          cursor pointer
        }
        }
      }
      .detail{
        width 100%
        height 87%
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #82D5FF80 ;
        border-top none
        .avatar{
          height 13rem
          margin-bottom 2rem
          display flex
          flex-direction column
          justify-content  center
          align-items center
          .avatar_img{
            width 9.25rem
            height 10.5rem
          }
        }
        .inf{
          height calc(100% - 15rem)
          color #333333
          font-size 1.3rem
          .inf_l{
            border-top: 1px solid #009F88 ;
            border-right: 1px solid #009F88 ;
            text-align center
            padding-left 2rem
            height calc(100% / 4)
            display flex
            flex-direction column
            justify-content center
          }
          .inf_r{
            height calc(100% / 4)
            display flex
            flex-direction column
            justify-content center
            align-items left
            padding-left 1.5rem
            border-top: 1px solid #009F88 ;
          }
          .inf_l1{
            border-top: 1px solid #009F88 ;
            border-right: 1px solid #009F88;
            text-align center
            padding-left 2rem
            height calc(100% / 4)
            display flex
            flex-direction column
            justify-content center
          }
          .inf_r1{
            height calc(100% / 4)
            display flex
            flex-direction column
            justify-content center
            align-items left
            padding-left 1.5rem
            border-top: 1px solid #009F88 ;
          }
        }
      }
    }
    .two{
      width: 50%;
      height: 100%;
      background #DBEEEC
      margin 0 1.25rem 0 1.25rem
      display flex
      flex-direction column
      border-radius: 1rem;
      .title{
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        border: 1px solid #009F88;
        border-bottom none
        height 13%
        width 100%
        background #019B84
        display flex
        flex-direction row
        align-items center
        text-indent 2rem
        font-size 1.5rem
        color: #FFFFFF;
        justify-content space-between
        .l{
          width 80%
          height 100%
          display flex
          flex-direction row
          align-items center
        }
        .r{
          width 5rem
          height 2.5rem
          line-height 2.4rem
          text-indent 0
          display flex
          justify-content center
          background-image linear-gradient(to bottom, #BDE4DD,#06B198)
          border-radius: .3rem;
          font-size 1.2rem
          color #fff
          cursor pointer
          margin-right 2rem
        }
    }
      .detail{
        width 100%
        height 87%
        border-bottom-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        border: 1px solid #009F88 ;
        border-top none
        .inf{
          height 100%
          color #333333
          font-size 1.3rem
          .inf_l{
            border-top: 1px solid #009F88 ;
            border-right: 1px solid #009F88 ;
            text-align center
            padding-left 5rem
            height calc(100% / 9)
            display flex
            flex-direction column
            justify-content center
          }
          .inf_r{
            height calc(100% / 9)
            display flex
            flex-direction column
            justify-content center
            align-items left
            padding-left 1.5rem
            border-top: 1px solid #009F88 ;
          }
          .inf_l1{
            border-top: 1px solid #009F88 ;
            border-right: 1px solid #009F88;
            text-align center
            padding-left 5rem
            height calc(100% / 9)
            display flex
            flex-direction column
            justify-content center
          }
          .inf_r1{
            height calc(100% / 9)
            display flex
            flex-direction column
            justify-content center
            align-items left
            padding-left 1.5rem
            border-top: 1px solid #009F88 ;
          }
        }
      }
  }
  .three{
    width: 22%;
    height: 100%;
    background #DBEEEC
    display flex
    flex-direction column
    border-radius: 1rem;
    .title{
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
      border: 1px solid #009F88 ;
      border-bottom none
      height 13%
      width 100%
      background #019B84
      display flex
      flex-direction row
      align-items center
      text-indent 2rem
      font-size 1.5rem
      color: #FFFFFF;
    }
    .detail{
      width 100%
      height 87%
      border-bottom-left-radius: 1rem;
      border-bottom-right-radius: 1rem;
      border: 1px solid #009F88;
      border-top none
      color #FFFFFF
      .jfxx{
        height 20%
        padding-left 1rem
        div{
          opacity 0.6
          padding-top 2rem
          font-size: 1rem;
          color: #333333;
          letter-spacing: 0;
          line-height: 32px;
        }
      }
      .kyye{
        font-size 2rem
        color #FA6400
        width 100%
        height 38%
        display flex
        flex-direction column
        justify-content center
        align-items center
        .amt{
          font-weight: Bold;
          font-size: 3.5rem;
          color: #FA6400;
          text-align center
          -webkit-text-stroke:1px #FFFFFF;
        }
        .amt_dec{
          font-size: 1rem;
          color: #000000;
          letter-spacing: 0.03rem;
          text-align: center;
        }
      }
      .anniu{
        color #263489
        width 100%
        height 42%
        display flex
        flex-direction column
        justify-content center
        align-items center
        .jfjl{
          width: 13.125rem;
          height: 4.6875rem;
          /*ackground-image: linear-gradient(180deg, #F2F4FF 0%, #8ca0ee 100%);*/
          background #00B097
          border-radius: 0.75rem
          line-height 4.6875rem
          text-align center
          font-size: 1.5rem;
          color: #fff;
          cursor pointer
        }
        .lzjl{
          width: 13.125rem;
          height: 4.6875rem;
          margin-top 1.5rem
         /* background-image: linear-gradient(180deg, #FFFAF2 0%, #FFDDD8 100%);*/
          background #00B097
          border-radius: 0.75rem;
          line-height 4.6875rem
          text-align center
          font-size: 1.5rem;
          color: #fff;
          cursor pointer
        }
      }
    }
  }
}
/deep/ .fakeDialog {
  .el-dialog__header {
    padding-left 0
    border-bottom: 1px solid #e3e8ee;
    background-color #F1F4FF;
    border-top-right-radius 10px
    border-top-left-radius 10px
  }

  .el-dialog__body {
    background-color #F1F4FF;
  }

  .el-dialog__footer {
    background-color #F1F4FF;
    border-bottom-left-radius 10px
    border-bottom-right-radius 10px
    text-align center
  }

  .el-table{
    .el-table__body tr td{
      font-size 1.2rem
    }
    /*.el-table__body tr:hover>td{
      background-color: #134087;
    }*/
  }

}
.dialog_button{
  background-image linear-gradient(to bottom, #BDE4DD,#06B198)
  color #fff
  border-radius: 6px;
  font-size 1.2rem
}
</style>
